export class Constants {
    static DEFAULT_LOGO_URL = 'https://file.virginpulse.com/api/file/ko7IRJhBQmMaez5w0Blw';
    static MAX_YEAR_LENGTH = 4;
    static MAXIMUM_PASSWORD_LENGTH = 50;
    static MINIMUM_AGE = 16;
    static MINIMUM_BIRTH_YEAR = 1900;
    static MINIMUM_PASSWORD_LENGTH = 8;
    static PLACEHOLDER_STATE_ID = '-1';
    static USA_COUNTRY_CODE = 'US';
    static CANADA_COUNTRY_CODE = 'CA';

    static FIELD_NAME = {
        AGREEMENTS: 'agreements',
        COUNTRY: 'country',
        PHONE_NUMBER: 'phoneNumber',
        HOME_PHONE_NUMBER: 'homePhoneNumber',
        PHONE_NUMBER_CONSENT: 'phoneNumberConsent'
    };

    static COMPONENT_TYPE = {
        AGREEMENT_CHECKBOX: 'agreement',
        APP_DOWNLOAD: 'app-download',
        BIRTH_YEAR: 'birth-year',
        BUSINESS_UNIT: 'business-unit',
        COMPANY: 'company',
        CONFIRMATION_EMAIL_SENT: 'confirmation-email-sent',
        COUNTRY: 'country',
        DATE_OF_BIRTH: 'birth-date',
        DISPLAY_TEXT: 'display-text',
        DROPDOWN: 'dropdown',
        EMAIL_ADDRESS: 'email-address',
        ENROLLMENT_SUCCESS_CONFIRMATION: 'enrollment-success-confirmation',
        OFFICE: 'office-location',
        PASSWORD: 'password',
        PHONE_NUMBER: 'phone-number',
        PRODUCT_SELECT: 'product-select',
        SECURITY_QUESTIONS: 'securityQuestions',
        STATE_PROVINCE: 'state-province',
        STATE_OF_RESIDENCE: 'state-of-residence',
        TEXTBOX: 'text',
        USERNAME: 'username',
        WAITLIST_CONFIRMATION: 'waitlist-confirmation',
        CHECKBOX: 'checkbox'
    };

    static CUSTOM_ELIGIBILITY_DATA_SOURCE = {
        AETNA_VSI: 'AetnaVSI',
        SAML: 'saml'
    };

    static PAGE_NAME = {
        HOME: 'home',
        ENROLLMENT_GROUP_SELECTION: 'enrollmentGroupSelection',
        WAITLIST: 'waitlist',
        REGISTRATION_CLOSED_AGAIN: 'registrationClosedAgain',
        CLOSED_REGISTRATION: 'closedRegistration',
        MAINTENANCE_REGISTRATION: 'maintenanceRegistration',
        FLEX_ENROLLMENT_GROUP_SELECTION: 'flexEnrollmentGroupSelection',
        FLEX: 'flex'
    }

    static PAGE_TYPE = {
        VERIFICATION_EMAIL_SENT: 'VerificationEmailSent',
        ENROLLMENT_SUCCESSFUL: 'EnrollmentSuccessful',
        AGREEMENTS: 'Agreements',
        VALIDATION: 'Validation',
        VALIDATION_TIE_BREAKER: 'ValidationTieBreaker',
        ACCOUNT_INFORMATION: 'AccountInformation',
        SPONSOR_CAP_WAITLIST: 'SponsorCapWaitlist',
        SPONSOR_CAP_WAITLIST_CONFIRMATION: 'SponsorCapWaitlistConfirmation',
        COMPANY_CAP_WAITLIST: 'CompanyCapWaitlist',
        COMPANY_CAP_WAITLIST_CONFIRMATION: 'CompanyCapWaitlistConfirmation',
        REGISTRATION_CLOSED: 'RegistrationClosed',
        REGISTRATION_CLOSED_AGAIN: 'RegistrationClosedAgain',
        PRODUCT_SELECT: 'ProductSelection',
        REGISTRATION_SCHEDULED: 'RegistrationScheduled',
        SPONSOR_SEARCH: 'SponsorSearch',
        ENROLLMENT_GROUP_SELECTION: 'EnrollmentGroupSelection'
    };

    static REGEX: { [key: string]: RegExp } = {
        DATE: /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/,
        LOWERCASE: /^.*[a-z].*$/,
        NUMBER: /^.*[0-9].*$/,
        SYMBOL: /^.*[!#$%()*+@_\-].*$/,
        UPPERCASE: /^.*[A-Z].*$/,
        USERNAME: /^[a-zA-Z0-9]{5,30}$/
    };

    static VALIDATORS: { [key: string]: string } = {
        CONFIRMATION: 'confirmation',
        DATE_FORMAT: 'dateFormat',
        EMAIL_ADDRESS: 'emailAddress',
        LOWERCASE: 'lowercase',
        MAX: 'max',
        MIN: 'min',
        MAX_AGE: 'maxAge',
        MIN_AGE: 'minAge',
        MIN_LENGTH: 'minLength',
        PASSWORD_LENGTH: 'passwordLength',
        NUMBER: 'number',
        SYMBOL: 'symbol',
        MIN_SECURITY_QUESTION_1_ANSWER_LENGTH: 'minSecurityQuestion1AnswerLength',
        MIN_SECURITY_QUESTION_2_ANSWER_LENGTH: 'minSecurityQuestion2AnswerLength',
        MIN_SECURITY_QUESTION_3_ANSWER_LENGTH: 'minSecurityQuestion3AnswerLength',
        UPPERCASE: 'uppercase',
        USERNAME: 'username',
        VALID_PHONE_NUMBER: 'validPhoneNumber'
    };

    static FORM_STATUS = {
        VALID: 'VALID'
    }
}
