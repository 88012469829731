<div class="phone-number-groove-override">
    <app-groove-phone-number-field class="groove-wrapper"
                                   [id]="field?.fieldName + '-input'"
                                   [fieldName]="field?.fieldName"
                                   [label]="field?.displayName"
                                   [isDisabled]="field?.editable === false"
                                   (valueChanged)="onChange($event)"
                                   (touched)="onTouch()"
                                   [countries]="countryOptions"
                                   [value]="phoneNumber"
                                   [selectedCountryCode]="selectedCountryCode"
                                   (errors)="onError($event)">
    </app-groove-phone-number-field>

    <small translate="" role="alert" class="error-text" [id]="field?.fieldName + '-valid-phone-number-error'"
        *ngIf="errors && errors[VALIDATORS.VALID_PHONE_NUMBER] && touched">
        enrollment.flex.errors.invalidPhoneNumber
    </small>
</div>
